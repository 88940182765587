import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField"; // Import TextField for input fields
import { addNewStaffMember, editDocument } from "../firebase/firestore";

interface StaffRegistrationModalProps {
  open: boolean;
  handleClose: () => void;
  data: {
    id: string;
    staffName: string;
    extension: string;
  };
  action: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "16px",
};

function StaffRegistrationModal({
  open,
  handleClose,
  data,
  action,
}: StaffRegistrationModalProps) {
  const [staffName, setStaffName] = React.useState("");
  const [extension, setExtension] = React.useState("");

  const handleChangeStaffName = (event: { target: { value: any } }) => {
    // Validate staff name
    const input = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(input)) {
      setStaffName(input);
    }
  };

  const handleChangeExtension = (event: { target: { value: any } }) => {
    // Validate extension
    const input = event.target.value;
    if (/^\d*$/.test(input)) {
      setExtension(input);
    }
  };

  const handleSubmit = async () => {
    // Regular expressions for validation
    const nameRegex = /^[a-zA-Z\s]*$/; // Accepts English alphabets and spaces only
    const extensionRegex = /^\d+$/; // Accepts numbers only

    if (!staffName.match(nameRegex)) {
      console.error("Staff Name should contain English alphabets only.");
      return;
    }

    if (!extension.match(extensionRegex)) {
      console.error("Extension should contain numbers only.");
      return;
    }
    if (action === "edit") {
      await editDocument("staffs", data.id, {
        staffName: staffName,
        extension: `Ext.${extension}`,
      });
    } else {
      await addNewStaffMember(staffName, extension);
    }

    // Reset input fields after submission
    setStaffName("");
    setExtension("");

    handleClose();
  };
  React.useEffect(() => {
    if (action === "edit") {
      setStaffName(data.staffName);
      setExtension(data && data.extension.split("Ext.")[1]);
    } else {
      setStaffName("");
      setExtension("");
    }
  }, [action, data]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style} onClick={(e) => e.stopPropagation()}>
          <Box></Box>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Staff Registration
          </Typography>
          <TextField
            label="Staff Name"
            variant="outlined"
            value={staffName}
            onChange={handleChangeStaffName}
            fullWidth
            sx={{ mt: 3 }}
          />
          <TextField
            label="Extension"
            variant="outlined"
            value={extension}
            onChange={handleChangeExtension}
            fullWidth
            sx={{ mt: 3 }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                mt: 3,
                backgroundColor: "#E6672E",
                ":hover": { backgroundColor: "#E6672E" },
              }}
            >
              {action === "edit" ? "Update" : "Register"}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default StaffRegistrationModal;
