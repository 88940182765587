import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  IconButton,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Logout,
  SupportAgentOutlined,
  Summarize,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Logo from "../assets/images/logo.png";
import { IUser as User } from "../interface/auth";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles({
  logoutIcon: {
    color: "#DD632C",
  },
  staffIcon: {
    color: "#DD632C",
  },
});
interface NavBarProps {
  user: User | null;
  onLogout: () => void;
}
const NavBar: React.FC<NavBarProps> = ({ user, onLogout }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const isAuthenticated = user !== null; // Placeholder for authentication status
  const location = useLocation();
  const currentRoute = location.pathname;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    onLogout();
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#282828", marginTop: "-8px" }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{ height: "40px", marginRight: "16px" }}
          />
        </div>

        <Box sx={{ display: { xs: "none", md: "block" } }}>
          {isAuthenticated && (
            <Grid container>
              <Button
                color="inherit"
                startIcon={
                  currentRoute === "/" ? (
                    <SupportAgentOutlined className={classes.staffIcon} />
                  ) : (
                    <Summarize className={classes.staffIcon} />
                  )
                }
                onClick={() => navigate(currentRoute === "/" ? "/staff" : "/")}
                sx={{ marginRight: "16px" }}
              >
                {currentRoute === "/" ? "Manage Staff" : "Reports"}
              </Button>
              <Button
                color="inherit"
                startIcon={<Logout className={classes.logoutIcon} />}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Grid>
          )}
        </Box>
        <Box sx={{ display: { md: "none" } }}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
            sx={{ display: isSmallScreen ? "block" : "none" }}
          >
            <MenuIcon sx={{ color: "#DD632C" }} />
          </IconButton>
        </Box>
        {isSmallScreen && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{ display: isSmallScreen ? "block" : "none" }}
          >
            {isAuthenticated && (
              <>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                <MenuItem
                  onClick={() =>
                    navigate(currentRoute === "/" ? "/staff" : "/")
                  }
                >
                  {currentRoute === "/" ? "Manage Staff" : "Reports"}
                </MenuItem>
              </>
            )}
          </Menu>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
