import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";

export const login = async (email: string, password: string) => {
  try {
    const user = await signInWithEmailAndPassword(auth,email, password);
    return{
      userDisplayName: user.user.displayName,
      isLogin: true
    }
  } catch (error:any) {
    console.error("Error signing in with email and password:", error.message);
  }
};

export const signOut = async () => {
  try {
    await auth.signOut();
  } catch (error:any) {
    console.error("Error signing out:", error.message);
  }
};
