import React, { useState, useEffect } from "react";
import {
  Divider,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Logo from "../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#282828",
    color: "#f9faf8",
    padding: "20px 0px 10px 0px",
    width: "100%",
    height: "auto",
    transition: "transform 0.3s ease-in-out",
    transform: "translateY(0)",
    // position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 1000,
  },
  hidden: {
    transform: "translateY(100%)",
  },
  logo: {
    width: "100%",
    maxWidth: 200,
    marginBottom: "16px",
  },
  icon: {
    marginRight: "8px",
  },
  link: {
    textDecoration: "none!important",
    color: "#FFFFFF!important",
    fontSize: "0.7em!important",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    function handleResize() {
      const contentHeight =
        document.documentElement.scrollHeight -
        (document.documentElement.clientHeight || window.innerHeight);
      const shouldHide =
        contentHeight <= window.scrollY ||
        document.documentElement.scrollHeight <=
          document.documentElement.clientHeight;
      setShouldHideFooter(shouldHide);
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <footer
      className={`${classes.footer} ${
        shouldHideFooter ? classes.hidden : ""
      }`}
      style={{
        marginLeft: isSmallScreen ? "-8px" : "0px",
      }}
    >
      <Grid sx={{ textAlign: "center" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <img src={Logo} alt="YUMA Logo" className={classes.logo} />
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid
              container
              spacing={0}
              sx={{ textAlign: "left", mx: isSmallScreen ? "20px" : "auto" }}
            >
                          <Grid item xs={6} md={3}>
                <Typography gutterBottom>Tel</Typography>
                <Typography>
                  {!isSmallScreen && (
                    <FontAwesomeIcon icon={faPhone} className={classes.icon} />
                  )}
                  <Link className={classes.link} href="tel:01617060396">
                    0161 706 0396
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography gutterBottom>Email</Typography>
                <Typography>
                  {!isSmallScreen && (
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className={classes.icon}
                    />
                  )}
                  <Link
                    className={classes.link}
                    href="mailto:Support@yumapos.co.uk"
                  >
                    Support@yumapos.co.uk
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography gutterBottom>Website</Typography>
                <Typography>
                  {!isSmallScreen && (
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className={classes.icon}
                    />
                  )}
                  <Link
                    className={classes.link}
                    target="_blank"
                    href="https://www.yumapos.co.uk"
                  >
                    www.yumapos.co.uk
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography gutterBottom>Company</Typography>
                <Typography className={classes.link}>YUMAPOS UK LTD</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ backgroundColor: "#808080", marginTop: "10px" }} />
      <Typography variant="body2" align="center" style={{ marginTop: "10px" }}>
        &#169; Copyright 2020 YUMAPOS UK LTD - All rights reserved
      </Typography>
    </footer>
  );
};

export default Footer;
