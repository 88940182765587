import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import Logo from "../assets/images/logo2.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { IUser as User } from "../interface/auth";
import { login } from "../firebase/auth";

interface Props {
  onLogin: (user: User) => void;
}

const Login: React.FC<Props> = ({ onLogin }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response: any = await login(email, password);
      if (!response) {
        setReturnMessage("Login failed: Invalid email or password");
        setIsLoading(false);
        return;
      }

      setReturnMessage("Login successful!");
      onLogin(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error signing in", error);
      setReturnMessage("Login failed: " + error);
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: !isSmallScreen ? "75vh" : "30vh",
          }}
        >
          <div
            style={{
              backgroundColor: "#282828",
              borderRadius: "100%",
              border: "16px solid #282828",
              width: "30%",
            }}
          >
            <img style={{ width: "100%" }} src={Logo} alt="Logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // height: !isSmallScreen ? "75vh" : "30vh",
          }}
        >
          <div style={{ margin: "0 auto" }}>
            <form onSubmit={handleLogin}>
              <Card
                variant="outlined"
                style={{
                  borderRadius: "10px",
                  padding: "20px",
                  width: isSmallScreen ? "80%" : "100%",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.40)",
                  marginBottom: isSmallScreen ? "20px" : "0",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    component="h3"
                    style={{
                      marginBottom: "20px",
                      textAlign: "center",
                      color: "#E6672E",
                      fontWeight: "bold",
                    }}
                  >
                    Login
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormControl fullWidth style={{ marginBottom: "20px" }}>
                      <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="standard"
                        required
                      />
                    </FormControl>
                    <FormControl fullWidth style={{ marginBottom: "20px" }}>
                      <TextField
                        label="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="standard"
                        type={showPassword ? "text" : "password"}
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    {returnMessage && (
                      <Typography
                        variant="body1"
                        style={{
                          color: "green",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        {returnMessage}
                      </Typography>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: "#E6672E", color: "#fff" }}
                      disabled={isLoading}
                    >
                      {isLoading ? "Logging in..." : "Login"}
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </form>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
