import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import StaffTable from "../components/StaffTable";
import StaffRegistrationModal from "../components/StaffRegistrationModal";
import { fetchDocumentsFromCollection } from "../firebase/firestore";

const Staff = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [rows, setRows] = React.useState<any[]>([]);
  const [editRow, setEditRow] = React.useState<any>(null);
  const [action, setAction] = React.useState("add");

  const fetchStaffData = async () => {
    try {
      fetchDocumentsFromCollection("staffs").then((data: any) => {
        setRows(data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseModal = async () => {
    setOpenModal(false);
    setAction("add");
    await fetchStaffData();
  };
  const handleEditRow = async (data: any) => {
    setAction("edit");
    setEditRow(data);
    setOpenModal(true);
    await fetchStaffData();
  };
  React.useEffect(() => {
    fetchStaffData();
  }, []);
  return (
    <>
      <Container>
        <Box textAlign={"center"} mt={5}>
          <Typography variant="h3" fontWeight={"bold"}>
            STAFF
          </Typography>
        </Box>
        <Box mt={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#E6672E",
              ":hover": { backgroundColor: "#E6672E" },
            }}
            onClick={() => setOpenModal(true)}
          >
            Add Staff
          </Button>
        </Box>
        <Box mt={2}>
          <StaffTable
            data={rows}
            loadUpdatedData={fetchStaffData}
            editRow={handleEditRow}
          />
        </Box>
      </Container>
      <StaffRegistrationModal
        open={openModal}
        handleClose={handleCloseModal}
        data={editRow}
        action={action}
      />
    </>
  );
};

export default Staff;
