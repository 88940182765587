import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { login, signOut } from "./firebase/auth";
import { IUser as User } from "./interface/auth";
import { Box } from "@mui/material";
import Staff from "./pages/Staff";

function App() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const userData = sessionStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    } else {
      console.log("No user found");
      setUser(null); // Reset user state if no user data is found
    }
  }, []);

  const handleLogin = async (user: User) => {
    try {
      sessionStorage.setItem("user", JSON.stringify(user));
      setUser(user);
    } catch (error) {
      console.error("Error signing in", error);
    }
  };

  const handleLogout = async () => {
    await signOut();
    sessionStorage.removeItem("user");
    setUser(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Navbar user={user} onLogout={handleLogout} />
        <Box sx={{ flex: 1, overflowY: "auto" }}>
          <Routes>
            <Route
              path="/"
              element={user ? <Home /> : <Navigate to="/login" />}
            />
            <Route
              path="/staff"
              element={user ? <Staff /> : <Navigate to="/login" />}
            />
            <Route
              path="/login"
              element={
                user ? <Navigate to="/" /> : <Login onLogin={handleLogin} />
              }
            />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default App;
