import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const CountCard = ({ label = "", value = 0 }) => {
  return (
    <Grid
      item
      xs={4.9}
      md={2.5}
      sx={{
        height: "4rem",
        backgroundColor: "#F2DB23",
        borderRadius: 5,
        boxShadow: 10,
        textAlign: "center",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        margin: { xs: 1, md: 2 },
      }}
      // margin={xs:1, md:2}
    >
      <Grid container px={3}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
            color="#525252"
          >
            {label}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            color="#525252"
            sx={{ fontSize: { xs: "1.3rem", md: "2rem" } }}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CountCard;
