import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteDocument } from "../firebase/firestore";
import ConfirmationDialog from "./ConfirmationDialog";
interface Column {
  id: "staffName" | "extension" | "actions";
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "staffName", label: "Name", minWidth: 170, align: "center" },
  { id: "extension", label: "Extension", minWidth: 100, align: "center" },
  { id: "actions", label: "Actions", minWidth: 100, align: "center" },
];

interface RowData {
  id: string;
  staffName: string;
  extension: string;
}
interface StaffTableProps {
  data: RowData[];
  loadUpdatedData: () => void;
  editRow: (row: RowData) => void;
}

export default function StaffTable({
  data,
  loadUpdatedData,
  editRow,
}: StaffTableProps) {
  const [rows, setRows] = React.useState(data);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<RowData | null>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (row: RowData) => {
    try {
      editRow(row);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleOpenDialog = (row: RowData) => {
    setSelectedRow(row);
    setOpenConfirmation(true);
  };

  const handleCloseDialog = () => {
    setOpenConfirmation(false);
  };

  const handleAgree = async () => {
    try {
      await deleteDocument("staffs", selectedRow?.id || "");
      loadUpdatedData();
    } catch (error: any) {
      console.log(error);
    }
    handleCloseDialog();
  };
  React.useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ fontWeight: "bold" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                      {column.label}
                      {/* <FilterListIcon /> */}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.length > 0 &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: RowData) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.staffName} // Assuming 'staffName' is the unique identifier for each row
                      >
                        {columns.map((column) => {
                          const value: string = row[column.id as keyof RowData]; // Correctly access value from 'row' using column id
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "actions" ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 2,
                                    justifyContent: "center",
                                  }}
                                >
                                  <EditIcon
                                    sx={{
                                      cursor: "pointer",
                                      color: "#E6672E",
                                      ":hover": { transform: "scale(1.2)" },
                                    }}
                                    onClick={() => handleEdit(row)}
                                  />
                                  <DeleteIcon
                                    sx={{
                                      cursor: "pointer",
                                      color: "#E6672E",
                                      ":hover": { transform: "scale(1.2)" },
                                    }}
                                    // onClick={() => handleDelete(row.id)}
                                    onClick={() => handleOpenDialog(row)}
                                  />
                                </Box>
                              ) : column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows && rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        open={openConfirmation}
        handleClose={handleCloseDialog}
        title="Are you sure you want to delete?"
        onDisagree={handleCloseDialog}
        onAgree={handleAgree}
        content={""}
      />
    </>
  );
}
