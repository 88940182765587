import { firestore } from "./firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";

export const fetchDocumentsFromCollection = async (collectionName: string) => {
  try {
    const querySnapshot = await getDocs(collection(firestore, collectionName));
    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return documents;
  } catch (error) {
    console.error("Error fetching documents: ", error);
    return [];
  }
};

export const fetchCallsDocuments = async () => {
  try {
    const q = query(
      collection(firestore, "callDetails"),
      orderBy("timestamp", "desc")
    );
    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return documents;
  } catch (error) {
    console.error("Error fetching documents: ", error);
    return [];
  }
};

export const addNewStaffMember = async (
  staffName: string,
  extension: string
): Promise<string> => {
  try {
    const docRef = await addDoc(collection(firestore, "staffs"), {
      staffName: staffName,
      extension: `Ext.${extension}`,
    });

    return docRef.id;
  } catch (error) {
    console.error("Error adding staff member: ", error);
    throw new Error("Failed to add staff member");
  }
};

export const deleteDocument = async (
  collectionName: string,
  documentId: string
) => {
  try {
    const documentRef = doc(firestore, collectionName, documentId);
    await deleteDoc(documentRef);
    console.log("Document successfully deleted!");
  } catch (error) {
    console.error("Error deleting document: ", error);
    throw new Error("Failed to delete document");
  }
};

export const editDocument = async (
  collectionName: string,
  documentId: string,
  newData: any
) => {
  try {
    const documentRef = doc(firestore, collectionName, documentId);
    await setDoc(documentRef, newData, { merge: true });
    console.log("Document successfully edited!");
  } catch (error) {
    console.error("Error editing document: ", error);
    throw new Error("Failed to edit document");
  }
};
