import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import FilterListIcon from "@mui/icons-material/FilterList";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useState } from "react";
import moment from "moment";
import { Rating } from "@mui/material";
import { fetchDocumentsFromCollection } from "../firebase/firestore";

interface Column {
  id:
    | "fromno"
    | "tono"
    | "duration"
    | "feedback"
    | "recievedTime"
    | "fromStaffName"
    | "email"
    | "personName"
    | "url"
    | "accountName"
  label: string;
  minWidth?: number;
  align?: "center" | "left" | "right";
  format?: (value: number) => string;
  filterApplied?: boolean;
  filterOption?: string;
  filterValue?: string;
}
type SortBy =
  | "fromStaffName"
  | "accountName"
  | "personName"
  | "email"
  | "url"
  | "fromno"
  | "tono"
  | "duration"
  | "feedback"
  | "recievedTime";
type SortOrder = "asc" | "desc";

const columns: readonly Column[] = [
  {
    id: "fromStaffName",
    label: "Staff Name",
    minWidth: 100,
    align: "center",
    filterOption: "contains",
  },
  {
    id: "accountName",
    label: "Account Name",
    minWidth: 100,
    align: "center",
    filterOption: "contains",
  },
  {
    id: "personName",
    label: "Contact Person",
    minWidth: 100,
    align: "center",
    filterOption: "contains",
  },
  {
    id: "email",
    label: "Contact Email",
    minWidth: 100,
    align: "center",
    filterOption: "contains",
  },
  {
    id: "tono",
    label: "To",
    minWidth: 100,
    align: "center",
    filterOption: "contains",
  },
  {
    id: "duration",
    label: "Call Duration",
    minWidth: 100,
    align: "center",
    filterOption: "contains",
  },
  {
    id: "recievedTime",
    label: "Call Recieved Time",
    minWidth: 100,
    align: "center",
    filterOption: "contains",
  },
  {
    id: "feedback",
    label: "Rating",
    minWidth: 100,
    align: "center",
    filterOption: "contains",
  },
  {
    id: "url",
    label: "Account Details",
    minWidth: 100,
    align: "center",
    filterOption: "contains",
  },
];

export default function StickyHeadTable(data: any = []) {
  const [rows, setRows] = useState(data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [staffs, setStaffs] = React.useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterColumnIndex, setFilterColumnIndex] = useState(0);
  const [filterData, setFilterData] = useState(columns);
  const [sortBy, setSortBy] = useState<SortBy>("recievedTime");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [sortedRows, setSortedRows] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState("All");
  const [staffFilterValue, setStaffFilterValue] = useState("All");
  const [avgRating, setAvgRating] = useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleTimeFrameFilter = (event: SelectChangeEvent) => {
    setFilterValue(event.target.value);
    setSortedRows(
      filterRowsBySelectedFilters(event.target.value, staffFilterValue)
    );
  };
  const handleStaffFilter = (event: SelectChangeEvent) => {
    setStaffFilterValue(event.target.value);
    setSortedRows(filterRowsBySelectedFilters(filterValue, event.target.value));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const fetchStaffs = async () => {
    try {
      const response: any = await fetchDocumentsFromCollection("staffs");
      if (response) {
        setStaffs(response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const injectStaffNames = (data: any[], staffs: any[]): any[] => {
    const staffMap: Record<string, string> = {};

    // Create a map of extensions to staff names
    staffs.forEach((staff) => {
      staffMap[staff.extension] = staff.staffName;
    });

    return data.map((call) => {
      const staffName = staffMap[call.fromno]; // Get staff name based on extension
      return staffName ? { ...call, fromStaffName: staffName } : call;
    });
  };
  const fetchData = async () => {
    try {
      await fetchStaffs();
      setRows(injectStaffNames(data.data, staffs));
      setSortedRows(injectStaffNames(data.data, staffs));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setFilterColumnIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = () => {
    const filteredData = filterRows(filterData[filterColumnIndex]);
    setRows(filteredData);
    const newArr = [...filterData];
    newArr[filterColumnIndex].filterApplied = true;
    setFilterData(newArr);
    handleClose();
  };

  const handleReset = () => {
    const newArr = [...filterData];
    newArr[filterColumnIndex].filterApplied = false;
    newArr[filterColumnIndex].filterOption = "contains";
    newArr[filterColumnIndex].filterValue = "";
    setFilterData(newArr);
    setRows(data.data);
  };

  const filterRows = (column?: Column) => {
    if (column) {
      switch (column.filterOption) {
        case "contains":
          return (
            rows &&
            rows.filter((row: any) =>
              row[column.id]
                .toLowerCase()
                .includes(column.filterValue?.toLowerCase())
            )
          );
        case "equals":
          return (
            rows &&
            rows.filter(
              (row: any) =>
                row[column.id].toLowerCase() ===
                column.filterValue?.toLowerCase()
            )
          );
        case "less than":
          return (
            rows &&
            rows.filter((row: any) => +row[column.id] < +column.filterValue!)
          );
        case "greater than":
          return (
            rows &&
            rows.filter((row: any) => +row[column.id] > +column.filterValue!)
          );
      }
    } else {
      return rows.filter((row: any) => {
        return filterData.every((col: any) => {
          if (!!col.filterApplied && col.filterValue !== "") {
            switch (col.filterOption) {
              case "contains":
                return row[col.id]
                  .toLowerCase()
                  .includes(col.filterValue?.toLowerCase());
              case "equals":
                return (
                  row[col.id].toLowerCase() === col.filterValue?.toLowerCase()
                );
              case "lessThan":
                return row[col.id] < col.filterValue!;
              case "greaterThan":
                return row[col.id] > col.filterValue!;
              default:
                return true;
            }
          } else {
            return true;
          }
        });
      });
    }
  };
  const filterRowsBySelectedFilters = (timeFrame: string, staff: string) => {
    // Filtering based on time frame
    let filteredRows = rows;
    const currentDate = moment();

    if (timeFrame === "last24hrs") {
      filteredRows = rows.filter((row: any) =>
        moment(row.recievedTime).isAfter(moment().subtract(24, "hours"))
      );
    } else if (timeFrame === "last7ds") {
      filteredRows = rows.filter((row: any) =>
        moment(row.recievedTime).isAfter(moment().subtract(7, "days"))
      );
    } else if (timeFrame === "last30ds") {
      filteredRows = rows.filter((row: any) =>
        moment(row.recievedTime).isAfter(moment().subtract(30, "days"))
      );
    } else if (timeFrame === "All") {
      filteredRows = rows;
    }

    // Filter based on staff
    if (staff !== "All" && staff !== "") {
      filteredRows = filteredRows.filter(
        (row: any) => row.fromStaffName === staff
      );
    }

    return filteredRows;
  };

  const handleSort = (columnId: string) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId as any);
      setSortOrder("asc");
    }
  };
  React.useEffect(() => {
    fetchData();
  }, [data]);

  React.useEffect(() => {
    if (sortedRows.length === 0) {
      setAvgRating(0);
      return;
    }
    const totalRating = sortedRows.reduce(
      (sum: number, row: any) => sum + +row.feedback,
      0
    );
    const averageRating = totalRating / sortedRows.length;
    setAvgRating(+averageRating.toFixed(1));
  }, [sortedRows]);

  React.useEffect(() => {
    if (!Array.isArray(rows)) {
      console.error("Rows is not an array.");
      return;
    }

    const updatedSortedRows = [ ...sortedRows].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
    });

    setSortedRows(updatedSortedRows);
  }, [rows, sortBy, sortOrder]);

  return (
    <Box sx={{ width: "96%", marginLeft: "auto", marginRight: "auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", flexDirection: { xs: "column", sm: "row" } }}>
        <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#E6672E",
            }}
          >
            Average:
          </Typography>
          <Rating name="customized-10" max={10} value={+avgRating} precision={0.2} readOnly
          //  size="large"
          sx={{
            fontSize:{xs:"0.8rem",sm:"2rem"},
            color: "#E6672E",
            "& .MuiRating-iconFilled": {
              color: "#E6672E",
            },
          }}
           />
          <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1.5rem" },
              fontWeight: "bold",
              color: "#E6672E",
            }}
          >
            ({avgRating})
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <FormControl sx={{ minWidth: 120, mb: 2 }}>
            <InputLabel
              sx={{
                color: "#E6672E",
                fontSize: "1.1rem",
                fontWeight: "bold",
                marginLeft:"-4px",
                "&.Mui-focused": { color: "#E6672E" },
              }}
              id="Staff"
            >
              Staff
            </InputLabel>
            <Select
              labelId="Staff"
              value={staffFilterValue}
              onChange={handleStaffFilter}
              label="Staff"
              size="medium"
              sx={{ width: 200, fontSize: "0.9rem" }}
            >
              <MenuItem value="All">All</MenuItem>
              {staffs.map((staff) => (
                <MenuItem value={staff.staffName} key={staff.id}>
                  {staff.staffName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 120, mb: 2 }}>
            <InputLabel
              sx={{
                color: "#E6672E",
                fontSize: "1rem",
                fontWeight: "bold",
                marginLeft:"-5px",
                "&.Mui-focused": { color: "#E6672E" },
              }}
              id="timeFrame"
            >
              Timeframe
            </InputLabel>
            <Select
              labelId="timeFrame"
              label="Timeframe"
              value={filterValue}
              onChange={handleTimeFrameFilter}
              size="medium"
              sx={{ width: 200, fontSize: "0.9rem" }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="last24hrs">Last 24 hours</MenuItem>
              <MenuItem value="last7ds">Last 7 days</MenuItem>
              <MenuItem value="last30ds">Last 30 days</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden", left: 0, right: 0, marginBottom: "20px" }}>
        <TableContainer >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ fontWeight: "bold", }}>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#F2DB23",
                      color: "#525252",
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {sortBy === column.id && ( // Render sorting icon based on current column
                        <ArrowDownwardIcon
                          sx={{
                            transform:
                              sortOrder === "desc"
                                ? "rotate(0deg)"
                                : "rotate(180deg)",
                            cursor: "pointer",
                            color: "#DD632C",
                            opacity: 0.5,
                          }}
                        />
                      )}
                      <Typography
                        sx={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={() => handleSort(column.id)}
                      >
                        {" "}
                        {column.label}
                      </Typography>

                      <div
                        onClick={(e) => {
                          handleClick(e, index);
                        }}
                      >
                        {/* {
                          <FilterListIcon
                            sx={{
                              color: "#DD632C",
                              marginLeft: "0.7rem",
                              cursor: "pointer",
                            }}
                          />
                        }
                        {!!filterData[filterColumnIndex].filterApplied && (
                          <span
                            style={{
                              color: "#DD632C",
                              marginBottom: "0.5rem",
                              fontSize: "0.8rem",
                              verticalAlign: "super",
                            }}
                          >
                            !
                          </span>
                        )} */}
                      </div>
                      <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Box
                          p={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "9rem",
                          }}
                        >
                          <Select
                            value={filterData[filterColumnIndex].filterOption}
                            onChange={(e) => {
                              const newArr = [...filterData];
                              newArr[filterColumnIndex].filterOption = e.target
                                .value as string;
                              setFilterData(newArr);
                            }}
                            displayEmpty
                            fullWidth
                            variant="outlined"
                            size="small"
                            sx={{ mb: 2 }}
                          >
                            <MenuItem value="contains">Contains</MenuItem>
                            <MenuItem value="equals">Equals</MenuItem>
                            <MenuItem value="greaterThan">
                              Greater than
                            </MenuItem>
                            <MenuItem value="lessThan">Less than</MenuItem>
                          </Select>
                          <OutlinedInput
                            placeholder="Enter value"
                            value={filterData[filterColumnIndex].filterValue}
                            onChange={(e) => {
                              const newArr = [...filterData];
                              newArr[filterColumnIndex].filterValue = e.target
                                .value as string;
                              setFilterData(newArr);
                            }}
                            fullWidth
                            size="small"
                            sx={{ mb: 2 }}
                          />
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Button
                                onClick={handleFilter}
                                // onClick={handleFilter(column.id)}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                sx={{
                                  color: "#E6672E",
                                  ":hover": {
                                    color: "#E6672E",
                                    borderColor: "#E6672E",
                                  },
                                  borderColor: "#E6672E",
                                }}
                              >
                                <CheckIcon sx={{ fontSize: "1rem" }} />
                                <Typography sx={{ fontSize: "0.8rem" }}>
                                  Filter
                                </Typography>
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                onClick={handleReset}
                                variant="contained"
                                fullWidth
                                size="small"
                                sx={{
                                  backgroundColor: "#E6672E",
                                  ":hover": { backgroundColor: "#E6672E" },
                                }}
                              >
                                <Typography sx={{ fontSize: "0.8rem" }}>
                                  Reset
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Popover>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows &&
                sortedRows.length > 0 &&
                sortedRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : column.id === "recievedTime" ? (
                                formatDate(value)
                              ) : column.id === "feedback" ? (
                                <>
                                  <Rating
                                    value={+value}
                                    precision={0.5}
                                    readOnly
                                    size="small"
                                    max={10}
                                    sx={{
                                      color: "#E6672E",
                                      "& .MuiRating-iconFilled": {
                                        color: "#E6672E",
                                      },
                                    }}
                                  />
                                </>
                              ) : column.id === "url" && value ? (
                                <>
                                  <a
                                    href={value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <OpenInNewIcon
                                      sx={{
                                        cursor: "pointer",
                                        color: "#DD632C",
                                      }}
                                    />
                                  </a>
                                </>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={(sortedRows && sortedRows.length) || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

const formatDate = (datetime: string): string => {
  return moment(datetime).format("DD MMM YYYY h:mm A");
};
