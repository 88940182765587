import React, { useEffect } from "react";
import { fetchCallsDocuments } from "../firebase/firestore";
import { Box, Grid } from "@mui/material";
import CountCard from "../components/CountCard";
import DataTable from "../components/DataTable";

const Home = () => {
  const [data, setData] = React.useState<any[]>([]);
  const [totalCalls, setTotalCalls] = React.useState(0);
  const [last24Hours, setLast24Hours] = React.useState(0);
  const [last7Days, setLast7Days] = React.useState(0);
  const [last30Days, setLast30Days] = React.useState(0);
  const fetchCallsData = async () => {
    try {
      const response = await fetchCallsDocuments();
      setData(response);
      CalculateTotals(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const CalculateTotals = (data: any[]) => {
    const last24Hours = data.filter((call) => {
      const callDate = new Date(call.recievedTime);
      const now = new Date();
      const diffHours =
        Math.abs(now.getTime() - callDate.getTime()) / (1000 * 60 * 60);
      return diffHours <= 24;
    });
    const last7Days = data.filter((call) => {
      const callDate = new Date(call.recievedTime);
      const now = new Date();
      const diffDays =
        Math.abs(now.getTime() - callDate.getTime()) / (1000 * 60 * 60 * 24);
      return diffDays <= 7;
    });

    const last30Days = data.filter((call) => {
      const callDate = new Date(call.recievedTime);
      const now = new Date();
      const diffDays =
        Math.abs(now.getTime() - callDate.getTime()) / (1000 * 60 * 60 * 24);
      return diffDays <= 30;
    });

    setTotalCalls(data.length);
    setLast24Hours(last24Hours.length);
    setLast7Days(last7Days.length);
    setLast30Days(last30Days.length);
  };

  useEffect(() => {
    fetchCallsData();
  }, []);
  return (
    <>
      <Box maxWidth="lg" sx={{ height: "99%", margin: "auto" }}>
        <Grid
          container
          sx={{
            marginTop: "1px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            width: "100%",
            height: "100%",
          }}
          padding={2}
        >
          <CountCard label="Total Calls" value={totalCalls} />
          <CountCard label="Last 24 Hours" value={last24Hours} />
          <CountCard label="Last 7 Days" value={last7Days} />
          <CountCard label="Last 30 Days" value={last30Days} />
        </Grid>
      </Box>
      <Box sx={{ marginTop: "2rem" }}>
        <DataTable data={data} />
      </Box>
    </>
  );
};

export default Home;
